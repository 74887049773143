const form = [
  {
    name: 'APP名称',
    key: 'appName',
    type: 'input'
  },
  {
    name: '联系我们',
    key: 'mobile',
    type: 'input'
  },
  {
    name: '版本号',
    type: 'input',
    key: 'versionNo'
  },
  {
    name: '版权中文',
    type: 'input',
    key: 'copyrightChn'
  },
  {
    name: '版权英文',
    type: 'input',
    key: 'copyrightEng'
  }
]
export default {
  type: 'form',
  url: '/config/farmPlateInfo/getById',
  edit: '/config/farmPlateInfo/update',
  data: form
}
